import ReactDOM from 'react-dom';
import { classList } from '~/utilities/css';

type Props = {
	message?: string;
	solid?: boolean;
	highest?: boolean;
};

function OverlayPortal({ message = 'One moment...', solid = false, highest = false }: Props) {
	return ReactDOM.createPortal(
		<p className={classList([
			`uic-fixed uic-inset-[0] ${highest ? 'uic-z-[9999]' : 'uic-z-10'}`,
			'uic-m-auto uic-w-full uic-h-screen',
			`${solid ? 'uic-bg-white/100' : 'uic-bg-white/95'} uic-font-quicksand uic-font-semibold`,
			'uic-flex uic-flex-col uic-items-center uic-justify-center',
		])}
		>
			<span className="uic-text-sm">{message}</span>
		</p>,
		document.body
	);
}

export { OverlayPortal as default, OverlayPortal };
